import { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useRef } from 'react'
import Logo from '../assets/newLogo.png'
import trackEvent from './Tracker/MixpanelConfig'
import Avatar from 'react-avatar'
import { useUser } from '../UserContext'

export default function Navbar() {
  const { user } = useUser()

  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false)
  const [showMobMenus, setShowMobMenus] = useState(false)
  const dropdownRef = useRef(null)
  const burgerMenuRef = useRef(null)

  const showProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen)
  }

  const showMobMenuDropdown = () => {
    setShowMobMenus(!showMobMenus)
  }

  useEffect(() => {
    function handleClickOutside(e) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsProfileDropdownOpen(false)
      }
      if (burgerMenuRef.current && !burgerMenuRef.current.contains(e.target)) {
        setShowMobMenus(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const displayName = user.displayName
  const userPicture = user.picture
  const userType = user.userType

  return (
    <div className='header'>
      <header className='header-container'>
        <Link to='/'>
          <img className='logo' src={Logo} alt='logo' />
        </Link>

        <div className='NavMenus'>
          <div
            ref={burgerMenuRef}
            className={`burgerMenu ${showMobMenus && 'active'}`}
          >
            <Link to='/leagues' style={{ marginRight: '15px' }}>
              All Leagues
            </Link>
            <Link to='/global-leader-board' style={{ marginRight: '15px' }}>
              Leaderboard
            </Link>
          </div>

          <div className='NavProfileButton'>
            {user.token ? (
              <div className='loggedinDropdown' onClick={showProfileDropdown}>
                <div className='avatarContainer'>
                  {userPicture ? (
                    <div
                      className='dpImg'
                      style={{ backgroundImage: `url(${userPicture})` }}
                    ></div>
                  ) : (
                    <Avatar
                      name={displayName}
                      size='40'
                      className='userdp'
                      textSizeRatio={2}
                    />
                  )}
                </div>

                {userType === 'admin' && (
                  <p className='navbar-admin-tag'>Admin</p>
                )}

                {isProfileDropdownOpen && (
                  <div ref={dropdownRef} className='dropdown'>
                    <li className='profileTitle'>
                      <p
                        className='oneLineText3dot'
                        style={{ marginInline: 'auto' }}
                      >
                        <b> {displayName} </b>
                      </p>
                    </li>
                    <Link to='/profile'>
                      <li className='list'>
                        <i className='bi bi-person'></i>
                        Profile{' '}
                        <i
                          className='bi bi-pencil'
                          style={{ fontSize: '0.5rem' }}
                        ></i>
                      </li>
                    </Link>
                    <Link to='/dashboard'>
                      <li className='list'>
                        <i className='bi bi-grid-1x2'></i>
                        Dashboard
                      </li>
                    </Link>
                    <Link to='/global-leader-board'>
                      <li className='list'>
                        <i className='bi bi-trophy'></i>
                        Leaderboard
                      </li>
                    </Link>
                    <Link to='/leagues'>
                      <li className='list'>
                        <i className='bi bi-hdd-stack'></i>
                        All Leagues
                      </li>
                    </Link>
                    <Link to='/joined-games'>
                      <li className='list'>
                        <i className='bi bi-person-plus'></i>
                        Joined Games
                      </li>
                    </Link>
                    <Link to='/Wallet'>
                      <li className='list' style={{ display: 'flex' }}>
                        <i className='bi bi-wallet2'></i>
                        <div>Wallet</div>
                      </li>
                    </Link>
                    <Link to='/refer'>
                      <li className='list' style={{ display: 'flex' }}>
                        <i className='bi bi-person-add'></i>
                        <div>Refer</div>
                      </li>
                    </Link>
                    <NavLink to='/logout'>
                      <li className='list'>
                        <i className='bi bi-box-arrow-right'></i> Logout
                      </li>
                    </NavLink>
                  </div>
                )}
              </div>
            ) : (
              <Link to='/sign-in'>
                <button
                  className='btn shine md-none '
                  onClick={() => {
                    trackEvent('JoinFC on Navbar Clicked')
                  }}
                >
                  Join The FC!
                </button>
              </Link>
            )}
          </div>

          {/* humburgure icon only show for non logged in user */}
          {!user.token && (
            <i
              className='burgerIcon bi bi-list'
              onClick={showMobMenuDropdown}
            ></i>
          )}
        </div>
      </header>
    </div>
  )
}
