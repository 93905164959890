export const AdminMenus = [
  {
    id: 1,
    name: 'Dashboard',
    icon: 'bi bi-grid-1x2',
    link: 'admin',
    show: true,
  },
  {
    id: 2,
    name: 'Leagues',
    icon: 'bi bi-grid-1x2',
    link: 'admin-league',
    show: true,
  },
  {
    id: 3,
    name: 'Game Rooms',
    icon: 'bi bi-grid-1x2',
    link: 'admin-game-room',
    show: true,
  },
  {
    id: 4,
    name: 'PlayerTransferValue',
    icon: 'bi bi-grid-1x2',
    link: 'player-transfer-value',
    show: true,
  },
  {
    id: 5,
    name: 'Log out',
    icon: 'bi bi-grid-1x2',
    link: 'logout',
    show: true,
  },
]
