import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Navbar from '../Component/Navbar'
import PulseLoading from '../Component/PulseLoading'
import axios from 'axios'
import getApiUrl from '../Component/getApiUrl'

function UnsubscribeEmail() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMsg, setErrMsg] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)

  // handleSubmit for unsubscribe email ======================================================================
  async function handleSubmit(e) {
    e.preventDefault() // Prevent default action if it's a form

    // Make sure the token exists
    if (!token) {
      console.error('Token not found in the URL')
      return
    }

    setIsLoading(true)
    setIsError(false)
    setIsSuccess(false)

    try {
      const baseUrl = getApiUrl()
      const response = await axios.patch(
        `${baseUrl}/api/v1/notifications/unsubscribe`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      setIsSuccess(true)
      console.log('Unsubscribe successful:', response.data)
    } catch (error) {
      setIsError(true)
      setErrMsg(error.response.data.error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Navbar />
      <div className='unsubscribeBox'>
        {isLoading ? (
          <PulseLoading />
        ) : (
          !isSuccess &&
          !isError && (
            <>
              <h1 style={{ color: '#07071c' }}>Want to unsubscribe?</h1>
              <div className='btn' onClick={handleSubmit}>
                Confirm
              </div>
            </>
          )
        )}

        {isError && (
          <h2 style={{ color: 'red' }}>
            {errMsg ? errMsg : 'Failed to unsubscribe. Please try again.'}
          </h2>
        )}

        {isSuccess && (
          <h2 style={{ color: 'green' }}>Successfully Unsubscribed</h2>
        )}
      </div>
    </>
  )
}

export default UnsubscribeEmail
