import { lazy } from 'react'
import PlayerTransferValue from './Admin/PointTransfer/PlayerTransferValue'
import UnsubscribeEmail from './Pages/UnsubscribeEmail'

const Loading = lazy(() => import('./Component/Loading'))
// pages
const Home = lazy(() => import('./Home'))
const SignIn = lazy(() => import('./Pages/SignIn'))
const SignUp = lazy(() => import('./Pages/SignUp'))
const ForgotPassword = lazy(() => import('./Pages/ForgotPassword'))
const Logout = lazy(() => import('./Pages/Logout'))
const ChangePassword = lazy(() => import('./Pages/ChangePassword'))
const ChangePasswordOTP = lazy(() => import('./Pages/ChangePasswordOTP'))
const EmailVerification = lazy(() => import('./Pages/EmailVerification'))

const Dashboard = lazy(() => import('./Dashboard/Dashboard'))
const Profile = lazy(() => import('./Profile/Profile'))
const Refer = lazy(() => import('./Refer/Refer'))
const GlobalLeaderBoard = lazy(
  () => import('./GlobalLeaderBoard/GlobalLeaderBoard')
)

// gameroom
const AllLeagues = lazy(() => import('./GameRoom/AllLeagues'))
const GameRooms = lazy(() => import('./GameRoom/GameRooms'))
const GRDetails = lazy(() => import('./GameRoom/GRDetails'))
const JoinedGames = lazy(() => import('./JoinedGames/JoinedGames'))

// team
const MyTeams = lazy(() => import('./MyTeams/MyTeams'))
const CreateTeam = lazy(() => import('./MyTeams/CreateTeam'))
const Wallet = lazy(() => import('./wallet/Wallet'))

// admin
const AdminDashboard = lazy(() => import('./Admin/AdminDashboard'))
const AdminLeague = lazy(() => import('./Admin/AdminLeague/AdminLeague'))
const AdminGameRoom = lazy(() => import('./Admin/AdminGameRoom/AdminGameRoom'))

export const Routings = [
  {
    id: 1,
    path: '/loading',
    element: 'Loading',
    userType: 'public',
    importFrom: Loading,
  },
  {
    id: 2,
    path: '/',
    element: 'Home',
    userType: 'public',
    importFrom: Home,
  },
  {
    id: 3,
    path: '/sign-in',
    element: 'SignIn',
    userType: 'public',
    importFrom: SignIn,
  },
  {
    id: 4,
    path: '/sign-up',
    element: 'SignUp',
    userType: 'public',
    importFrom: SignUp,
  },
  {
    id: 5,
    path: '/forgot-password',
    element: 'ForgotPassword',
    userType: 'public',
    importFrom: ForgotPassword,
  },
  {
    id: 6,
    path: '/change-password',
    element: 'ChangePassword',
    userType: 'public',
    importFrom: ChangePassword,
  },
  {
    id: 7,
    path: '/logout',
    element: 'Logout',
    userType: 'public',
    importFrom: Logout,
  },
  {
    id: 9,
    path: '/change-passwordOTP',
    element: 'ChangePasswordOTP',
    userType: 'public',
    importFrom: ChangePasswordOTP,
  },
  {
    id: 10,
    path: '/email-verification',
    element: 'EmailVerification',
    userType: 'public',
    importFrom: EmailVerification,
  },
  {
    id: 11,
    path: '/leagues',
    element: 'AllLeagues',
    userType: 'public',
    importFrom: AllLeagues,
  },
  {
    id: 12,
    path: '/league/:id/game-rooms',
    element: 'GameRooms',
    userType: 'public',
    importFrom: GameRooms,
  },
  {
    id: 14,
    path: '/wallet',
    element: 'Wallet',
    userType: 'user',
    importFrom: Wallet,
    isProtected: true,
  },
  {
    id: 15,
    path: '/my-teams',
    element: 'MyTeams',
    userType: 'user',
    importFrom: MyTeams,
  },
  {
    id: 16,
    path: '/create-team',
    element: 'CreateTeam',
    userType: 'user',
    importFrom: CreateTeam,
  },
  {
    id: 17,
    path: '/admin',
    element: 'AdminDashboard',
    userType: 'admin',
    importFrom: AdminDashboard,
    isProtected: true,
  },
  {
    id: 18,
    path: '/admin-league',
    element: 'AdminLeague',
    userType: 'admin',
    importFrom: AdminLeague,
    isProtected: true,
  },
  {
    id: 19,
    path: '/admin-game-room',
    element: 'AdminGameRoom',
    userType: 'admin',
    importFrom: AdminGameRoom,
    isProtected: true,
  },
  {
    id: 20,
    path: '/game-room/:id',
    element: 'GRDetails',
    userType: 'user',
    importFrom: GRDetails,
  },
  {
    id: 21,
    path: '/joined-games',
    element: 'JoinedGames',
    userType: 'user',
    importFrom: JoinedGames,
    isProtected: true,
  },
  {
    id: 22,
    path: '/dashboard',
    element: 'Dashboard',
    userType: 'user',
    importFrom: Dashboard,
    isProtected: true,
  },
  {
    id: 23,
    path: '/player-transfer-value',
    element: 'PlayerTransferValue',
    userType: 'admin',
    importFrom: PlayerTransferValue,
    isProtected: true,
  },
  {
    id: 24,
    path: '/profile',
    element: 'Profile',
    userType: 'user',
    importFrom: Profile,
    isProtected: true,
  },
  {
    id: 25,
    path: '/refer',
    element: 'Refer',
    userType: 'user',
    importFrom: Refer,
    isProtected: true,
  },
  {
    id: 26,
    path: '/global-leader-board',
    element: 'GlobalLeaderBoard',
    userType: 'user',
    importFrom: GlobalLeaderBoard,
    isProtected: false,
  },
  {
    id: 27,
    path: '/unsubscribe',
    element: 'UnsubscribeEmail',
    userType: 'public',
    importFrom: UnsubscribeEmail,
    isProtected: false,
  },
]
