import {
  // CONCORDIUM_WALLET_CONNECT_PROJECT_ID,
  MAINNET,
  TESTNET,
  WalletConnectConnector,
  ephemeralConnectorType,
  WalletConnectEvent,
  WalletConnectMethod,
  BrowserWalletConnector,
  CONCORDIUM_WALLET_CONNECT_PROJECT_ID,
} from '@concordium/react-components'
import { isProd } from './utils/isProd'
import { isPanenkaFCUrl } from './utils/isPanenkaFCUrl'

export const pageSizes = {
  small: 10,
  medium: 20,
  big: 50,
  super: 100,
}

export const DEFAULTPLAYERFILTERS = {
  page: 1,
  take: pageSizes.medium,
  sortBy: 'cost',
  sortOrder: 'DESC',
}

export const NET = isProd ? MAINNET : TESTNET

export const RECEIVER_ADDRESS = isProd
  ? '4NyaTPrcrwSQ1sGGjDAjeWMz8ZNRRWphDHex46aSW1VPM2wZwr'
  : '3zdLnv2NRjEEJ53PsUENXF8mhPFtgnD9TmfQMJGaHiQpeFcz3j'

export const googleConstants = {
  clientId:
    '1052068475021-racsgjbvs1531tmdf9bueand07bl3a9f.apps.googleusercontent.com',
  measuringId: 'G-4FBK8EYFWB',
}

export const wertConstants = {
  address: isProd ? 9390 : 7260,
  partnerId: isProd
    ? '01HQJVWPRN9FF86TEA2W1DS86Y'
    : '01HNZ92PXHBA51K90Z3AZCW4FH',
  defaultCommodity: isProd
    ? '[{"commodity": "USDT","network":"polygon"}]'
    : '[{"commodity": "MATIC","network":"mumbai"}]',
  origin: isProd ? 'https://widget.wert.io' : 'https://sandbox.wert.io',
  // ccdCommodity: `[{"commodity": "CCD", "network":  ${
  //   isProd ? '"concordium"' : '"testnet"'
  // } }, {"commodity": "EUROe", "network":  ${
  //   isProd ? '"mainnet"' : '"testnet"'
  // } } ]`,
  ccdCommodity: JSON.stringify([
    {
      commodity: 'CCD',
      network: isProd ? 'concordium' : 'testnet',
    },
  ]),
}

export const BROWSER_WALLET_NAME = 'Browser Wallet'
export const MOBILE_WALLET_NAME = 'Mobile Wallet'

export const CONCORDIUM = 'concordium'
export const POLYGON = 'polygon'
export const XDC = 'xdc'

export const MICRO = 1000000 // 10*6

// genrated from Smart contract - cargo concordium build --schema-json-out ./
export const SERIALIZATION_HELPER_SCHEMA_ADDITIONAL_DATA =
  'FAAEAAAADQAAAHNob3VsZF9lbnJvbGwBBwAAAHVzZXJfaWQVAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAAWAgwAAABnYW1lX3Jvb21faWQVAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAAWAgcAAAB0ZWFtX2lkFQIAAAAEAAAATm9uZQIEAAAAU29tZQEBAAAAFgI'

export const CCD_CONTRACT = 'ccd_contract'

export const CCD_CONTRACT_JOIN_GAME_ROOM_SHCEMA =
  'FAADAAAABwAAAHVzZXJfaWQWAgcAAAB0ZWFtX2lkFgIMAAAAZ2FtZV9yb29tX2lkFgI='

export const CCD_CONTRACT_VIEW_SCHEMA =
  'FAAJAAAAAgAAAGlkFgIFAAAAc3RhcnQNAwAAAGVuZA0FAAAAcHJpemUKBgAAAHdpbm5lchUCAAAABAAAAE5vbmUCBAAAAFNvbWUBAQAAAAsJAAAAZW50cnlfZmVlCgsAAABlbnRyeV9saW1pdAQHAAAAYmFsYW5jZQoSAAAAcGFydGljaXBhbnRzX2NvdW50BA=='

const WALLET_CONNECT_PROJECT_ID = '16b1ca7c7d713406b723845662426151'

const isPanenkaUrl = isPanenkaFCUrl(window.location.origin)

const WALLET_CONNECT_OPTS = {
  projectId: isPanenkaUrl
    ? WALLET_CONNECT_PROJECT_ID
    : CONCORDIUM_WALLET_CONNECT_PROJECT_ID, // if the enviroment/page is not on Panenka's domain we use Concordiums Id
  metadata: {
    name: 'Panenka FC',
    description: 'Fantasy Football',
    url: isProd ? 'https://www.panenkafc.gg' : 'https://test.panenkafc.gg',
    icons: ['https://walletconnect.com/walletconnect-logo.png'],
  },
}

export const WALLET_CONNECT_SCOPE = {
  methods: [
    WalletConnectMethod.SignMessage,
    WalletConnectMethod.SignAndSendTransaction,
  ],
  events: [WalletConnectEvent.AccountsChanged, WalletConnectEvent.ChainChanged],
}

export const WALLET_CONNECT = ephemeralConnectorType((delegate, network) =>
  WalletConnectConnector.create(
    WALLET_CONNECT_OPTS,
    delegate,
    network,
    WALLET_CONNECT_SCOPE
  )
)

export const BROWSER_XTENSION_WALLET = ephemeralConnectorType(
  BrowserWalletConnector.create
)

export const DEFAULT_TIEMOUT = 60000 // 60 sec

export const passwordValidationRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/

export const OLD_KEYS = [
  'displayName',
  'lastName',
  'userName',
  'userType',
  'hasVerifiedEmail',
  'redirect',
  'userId',
  'isLogin',
  'fetchWalletOnLoad',
  'changePasswordAccessToken',
  'userEmail',
  'userPicture',
]
