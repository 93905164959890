import { pageSizes, DEFAULTPLAYERFILTERS } from '../constants'
import { generateUrlParams } from '../utils/generateUrlParams'

// Wallet
export const walletUrl = 'wallets'

// Game room
export const joinGameRoomUrl = (id) => `game-rooms/${id}/join`
export const getGameRoomsUrl = (filter) =>
  `game-rooms?${generateUrlParams(filter)}`
export const getGameRoomUrlByIdUrl = (id) => `game-rooms/${id}`
export const getLineupByIdUrl = (id) => `teams/${id}`
export const getGameRoomFixturesUrl = (id) => `game-rooms/${id}/fixtures`
export const getJoinedGamesUrl = (id, page) =>
  `game-rooms/users/${id}?page=${page}&take=10&sort_by=createdAt&sort_order=DESC`
export const getTransactionUrl = (page, token) =>
  `payments?page=${page}&take=10&token=${token}`
export const getParticipantsUrl = (id, page, sortingOptions = {}) => {
  const sortingParams = generateUrlParams(sortingOptions) // This will turn { sort_by: "point", sort_order: "desc" } into "sort_by=point&sort_order=desc"
  return `game-rooms/${id}/participants?page=${page}&take=${pageSizes.medium}&${sortingParams}`
}
export const approvePayoutUrl = (id) => `game-rooms/${id}/process-pay-out`
export const processRunnerUpPayoutUrl = (id) =>
  `game-rooms/${id}/process-runner-ups-payout`
export const getGameRoomPointSummariesUrl = (id, pageNo) =>
  `game-rooms/${id}/points-summaries?page=${pageNo}&take=${pageSizes.medium}`

// Teams - User Built
export const userBuiltTeamUrl = 'teams'
export const getUserBuiltTeamUrlById = (id) => `teams/${id}`

// leagues
export const createAliasLeagueUrl = () => `leagues`
export const getAliasLeaguesUrl = (pageNo = 1) =>
  `leagues?page=${pageNo}&take=${pageSizes.super}`
export const getAliasLeagueUrlById = (id) => `leagues/${id}`
export const getFPLLeaguesUrl = (pageNo = 1) =>
  `fpl/leagues?page=${pageNo}&take=${pageSizes.super}`

// teams
export const getFPLTeamsUrl = (pageNo = 1) =>
  `fpl/teams?page=${pageNo}&take=${pageSizes.super}`
// Players
export const getPlayersUrl = (filter) => {
  const mergeFilters = {
    ...DEFAULTPLAYERFILTERS,
    ...filter,
  }
  // page=${page}&take=20&sortBy=cost&sortOrder=DESC&league=${league}
  return `fpl/players?${generateUrlParams(mergeFilters)}`
}
export const getPlayerByIdUrl = (id) => `fpl/players/${id}`
export const getTeamsNextMatchByIdUrl = (teamId) =>
  `fpl/fixtures?team=${teamId}`
export const createTeamUrl = () => `teams`
// Dashboard
export const investmentApiUrl = () => `reports/investments`
export const mostJoinedLeagueUrl = () => `reports/most-joined-leagues`
export const mostJoinedClubUrl = () => `reports/most-played-club`
export const countGameRoomsUrl = () => `reports/count-joined-game-rooms`
export const statisticsUrl = () => `reports/metric-scores`
export const highestPointGRUrl = () => `reports/highest-point`
export const last7GameStatsUrl = () => `reports/last-game-rooms`

// user
export const getUserDetailsUrl = () => `users/user/details`
export const getUpdateProfileUrl = (id) => `users/${id}`

// payment
export const requestCcdUrl = () => `payments/request-faucet`
export const runnerUpPayoutUrl = (gameRoomId) =>
  `game-rooms/${gameRoomId}/pay-participants`

// referrals
export const getReferralStatisticsUrl = () => `referrals/statistics`
export const getReferralsUrl = (page, startDate, endDate) => {
  const baseUrl = `referrals?page=${page}&take=10`
  const startDateParam = startDate !== '' ? `&startDate=${startDate}` : ''
  const endDateParam = endDate !== '' ? `&endDate=${endDate}` : ''

  return `${baseUrl}${startDateParam}${endDateParam}`
}

// Global Leaderboard
export const getLeaderTableUrl = (pageNo) =>
  `x-points/leaderboard?page=${pageNo}&take=20`

export const getUserLevelUrl = () => `x-points/leaderboard/user-position`

// balances
export const getWalletBalanceUrl = () => `wallets/balances`

// withdrawal
export const getWithdrawalUrl = () => `wallets/process-withdrawal`

// otp
export const getOtpUrl = () => `otp/generate`

// notification
export const getSubscribeUrl = () => `notifications/subscribe`
